<template>
  <b-table-simple 
    small 
    hover
    responsive
    bordered
    class="w-100"
    v-if="!loading"
  >
    <b-thead head-variant="light">
      <b-tr>
        <b-th>Дата, сервер</b-th>
        <b-th>Дата, GPS</b-th>
        <b-th>Тип сеанса</b-th>
        <b-th>Датчик</b-th>
        <b-th>ЛЭП</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="item in paginatedData"
        :key="item.id"
        @click="selectItem(item)"
      >
        <b-td>{{ formatDate(item.dateServer) }}</b-td>
        <b-td>{{ formatDate(item.dateUTC) }}</b-td>
        <b-td>{{ sessionType(item) }}</b-td>
        <b-td>{{ item.sensorId }}</b-td>
        <b-td>{{ widget.deviceName }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import axios from "axios";

export default {
  props: {
    widget: {
      type: Object,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    paginatedData() {
      return this.data;
    }
  },
  methods: {
    sessionType(el) {
      return el.flag != null ? 'Регистрация сигнала' : 'Плановый сеанс'
    },
    async fetchData() {
      if(this.loading) return;
      this.loading = true;
      this.$emit("loading", this.loading);
      try {
        const start =
          (this.pagination.currentPage - 1);
        let parameters = {
          powerlineId: this.widget.deviceId,
          page: start,
          size: this.pagination.perPage,
          sort: "date,desc",
          desc: true,
          startDate: this.startDate,
          endDate: this.endDate,
        };
        if (this.filters.sensorId.value) {
          parameters["sensorIds[]"] = this.filters.sensorId.value;
        }
        const resp = await axios.get(`api/oscillogram/measurements`, {
          params: parameters,
        });
        this.data = resp.data.content;
        this.totalCount = resp.data.totalElements;
        this.$emit("totalCountChanged", this.totalCount);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      } finally {
        this.loading = false;
        this.$emit("loading", this.loading);
      }
    },
    selectItem(item) {
      this.$emit("select", item.id);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    formatDateUnix(time) {
      const timestampInMilliseconds =
        time > 1e15 ? Math.floor(time / 1e6) : time;
      const date = new Date(time / 1000000);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    },
  },
  watch: {
    startDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    endDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    pagination() {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
h2 {
  margin-top: 20px;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.back-button:hover {
  color: #0056b3;
}

.pagination {
  margin-top: 20px;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-top: 20px;
}
</style>

<template>
  <b-pagination
    v-model="currentPage"
    :total-rows="totalPages"
    :per-page="perPage"
    aria-controls="my-table"
  ></b-pagination>
</template>
  
  <script>
  export default {
    props: {
      totalPages: {
        type: Number,
        required: true,
      },
      perPage: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        currentPage: 1,
        totalPagesConst: 1
      }
    },
    watch: {
      currentPage(newVal) {
        this.$emit('page-changed', newVal);
      }
    },
    mounted() {
      this.totalPagesConst = this.totalPages
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  .page-item.disabled .page-link {
    pointer-events: none;
    background-color: #e9ecef;
  }
  </style>
  
<template>
  <div class="container mt-4">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="row g-3">
        <div class="col-md-6">
          <b-form-group
            id="field-sensorNumber"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.SENSOR_NUMBER')"
            label-for="sensorNumber"
          >
            <b-form-input
              type="number"
              id="sensorNumber"
              v-model.number="sensorDto.id"
              :state="!errors.id"
            />
            <b-form-invalid-feedback v-if="errors.id">
              {{ errors.id }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-sensorName"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.SENSOR_NAME')"
            label-for="sensorName"
          >
            <b-form-input
              id="sensorName"
              v-model="sensorDto.name"
              :state="!errors.name"
            />
            <b-form-invalid-feedback v-if="errors.name">
              {{ errors.name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-sensorVersion"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.SENSOR_VERSION')"
            label-for="sensorVersion"
          >
            <b-form-input
              type="number"
              id="sensorVersion"
              v-model.number="sensorDto.sensorVersion"
              :state="!errors.sensorVersion"
            />
            <b-form-invalid-feedback v-if="errors.sensorVersion">
              {{ errors.sensorVersion }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-time_period"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.COMMUNICATION_PERIOD')"
            label-for="time_period"
          >
            <b-form-input
              type="number"
              id="time_period"
              v-model.number="sensorDto.time_period"
              :state="!errors.time_period"
            />
            <b-form-invalid-feedback v-if="errors.time_period">
              {{ errors.time_period }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <label for="powerlineId" class="form-label">{{
            $t("NEW_DASHBOARD.POWER_LINE")
          }}</label>
          <b-form-select
            v-model.number="sensorDto.powerlineId"
            :options="powerlineOptions"
            id="powerlineId"
            :class="{ 'is-invalid': errors.powerlineId }"
          ></b-form-select>
          <div v-if="errors.powerlineId" class="invalid-feedback">
            {{ errors.powerlineId }}
          </div>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-vertexId"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.VERTEX_NUMBER')"
            label-for="vertexId"
          >
            <b-form-input
              type="number"
              id="vertexId"
              v-model.number="sensorDto.vertexId"
              :state="!errors.vertexId"
            />
            <b-form-invalid-feedback v-if="errors.vertexId">
              {{ errors.vertexId }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-threshold"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.THRESHOLD')"
            label-for="threshold"
          >
            <b-form-input
              type="number"
              id="threshold"
              v-model.number="sensorDto.countValue"
              :state="!errors.countValue"
            />
            <b-form-invalid-feedback v-if="errors.countValue">
              {{ errors.countValue }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-ustavka_L"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.USTAVKA')"
            label-for="ustavka_L"
          >
            <b-form-input
              type="number"
              id="ustavka_L"
              v-model.number="sensorDto.ustavka_L"
              :state="!errors.ustavka_L"
            />
            <b-form-invalid-feedback v-if="errors.ustavka_L">
              {{ errors.ustavka_L }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-gainDA1_A"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.GAIN_DA1_A')"
            label-for="gainDA1_A"
          >
            <b-form-select
              id="gainDA1_A"
              v-model.number="sensorDto.gainDA1_A"
              :options="gainOptions"
              :state="!errors.gainDA1_A"
            />
            <b-form-invalid-feedback v-if="errors.gainDA1_A">
              {{ errors.gainDA1_A }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-gainDA1_B"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.GAIN_DA1_B')"
            label-for="gainDA1_B"
          >
            <b-form-select
              id="gainDA1_B"
              v-model.number="sensorDto.gainDA1_B"
              :options="gainOptions"
              :state="!errors.gainDA1_B"
            />
            <b-form-invalid-feedback v-if="errors.gainDA1_B">
              {{ errors.gainDA1_B }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-gainDA2_A"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.GAIN_DA2_A')"
            label-for="gainDA2_A"
          >
            <b-form-select
              id="gainDA2_A"
              v-model.number="sensorDto.gainDA2_A"
              :options="gainOptions"
              :state="!errors.gainDA2_A"
            />
            <b-form-invalid-feedback v-if="errors.gainDA2_A">
              {{ errors.gainDA2_A }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-gainDA2_B"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.GAIN_DA2_B')"
            label-for="gainDA2_B"
          >
            <b-form-select
              id="gainDA2_B"
              v-model.number="sensorDto.gainDA2_B"
              :options="gainOptions"
              :state="!errors.gainDA2_B"
            />
            <b-form-invalid-feedback v-if="errors.gainDA2_B">
              {{ errors.gainDA2_B }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-porog_MCF"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.MCF_THRESHOLD')"
            label-for="porog_MCF"
          >
            <b-form-input
              type="number"
              id="porog_MCF"
              v-model.number="sensorDto.porog_MCF"
              :state="!errors.porog_MCF"
            />
            <b-form-invalid-feedback v-if="errors.porog_MCF">
              {{ errors.porog_MCF }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-porogArtInt"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.ART_INT_THRESHOLD')"
            label-for="porogArtInt"
          >
            <b-form-input
              type="number"
              id="porogArtInt"
              v-model.number="sensorDto.porogArtInt"
              :state="!errors.porogArtInt"
            />
            <b-form-invalid-feedback v-if="errors.porogArtInt">
              {{ errors.porogArtInt }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-zeroNumber"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.LOGICAL_ZEROS')"
            label-for="zeroNumber"
          >
            <b-form-input
              type="number"
              id="zeroNumber"
              v-model.number="sensorDto.zeroNumber"
              :state="!errors.zeroNumber"
            />
            <b-form-invalid-feedback v-if="errors.zeroNumber">
              {{ errors.zeroNumber }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-step"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.TIME_STEP')"
            label-for="step"
          >
            <b-form-input
              type="number"
              id="step"
              v-model.number="sensorDto.step"
              :state="!errors.step"
            />
            <b-form-invalid-feedback v-if="errors.step">
              {{ errors.step }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            id="field-isVoltageCircuit"
            label-cols-lg="4"
            :label="$t('NEW_DASHBOARD.INPUT_CIRCUIT_TYPE')"
            label-for="isVoltageCircuit"
          >
            <b-form-select
              id="isVoltageCircuit"
              v-model.number="sensorDto.isVoltageCircuit"
              :options="[
                { value: 1, text: $t('NEW_DASHBOARD.VOLTAGE') },
                { value: 0, text: $t('NEW_DASHBOARD.CURRENT') },
              ]"
              :state="!errors.isVoltageCircuit"
            />
            <b-form-invalid-feedback v-if="errors.isVoltageCircuit">
              {{ errors.isVoltageCircuit }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddScatDeviceForm",
  props: {
    sensorData: {
      type: Object,
      default: () => ({
        id: null,
        sensorVersion: null,
        time_period: 900,
        powerlineId: null,
        vertexId: null,
        ustavka_L: 15,
        gainDA1_A: 1,
        gainDA1_B: 1,
        gainDA2_A: 1,
        gainDA2_B: 1,
        porog_MCF: null,
        porogArtInt: null,
        zeroNumber: null,
        step: null,
        isVoltageCircuit: 1,
        countValue: null,
        name: null,
      }),
    },
    powerlines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      gainOptions: [
        { value: 1, text: "1x" },
        { value: 2, text: "2x" },
        { value: 4, text: "4x" },
      ],
    };
  },
  computed: {
    sensorDto() {
      return { ...this.sensorData };
    },
    powerlineOptions() {
      return (
        this.powerlines.map((powerline) => ({
          value: powerline.id,
          text: powerline.name,
        })) || []
      );
    },
  },
  methods: {
    validateForm() {
      this.errors = {};

      if (!this.sensorDto.id) {
        this.errors.id = this.$t("NEW_DASHBOARD.SENSOR_NUMBER_REQUIRED");
      }

      if (!this.sensorDto.sensorVersion || this.sensorDto.sensorVersion < 400) {
        this.errors.sensorVersion = this.$t("NEW_DASHBOARD.VERSION_REQUIRED");
      }

      if (!this.sensorDto.name) {
        this.errors.name = this.$t("NEW_DASHBOARD.NAME_REQUIRED");
      }

      if (this.sensorDto.name && this.sensorDto.name.length > 50) {
        this.errors.name = this.$t("NEW_DASHBOARD.NAME_LENGTH");
      }

      if (
        !this.sensorDto.time_period ||
        this.sensorDto.time_period < 15 ||
        this.sensorDto.time_period > 1020
      ) {
        this.errors.time_period = this.$t(
          "NEW_DASHBOARD.COMMUNICATION_PERIOD_REQUIRED"
        );
      }

      if (!this.sensorDto.powerlineId) {
        this.errors.powerlineId = this.$t("NEW_DASHBOARD.POWER_LINE_REQUIRED");
      }

      if (!this.sensorDto.vertexId || this.sensorDto.vertexId > 9999) {
        this.errors.vertexId = this.$t("NEW_DASHBOARD.VERTEX_NUMBER_REQUIRED");
      }

      if (!this.sensorDto.countValue || this.sensorDto.countValue > 100) {
        this.errors.countValue = this.$t("NEW_DASHBOARD.THRESHOLD_REQUIRED");
      }

      if (this.sensorDto.ustavka_L < 0 || this.sensorDto.ustavka_L > 255) {
        this.errors.ustavka_L = this.$t("NEW_DASHBOARD.USTAVKA_REQUIRED");
      }

      if (!this.sensorDto.gainDA1_A) {
        this.errors.gainDA1_A = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA1_B) {
        this.errors.gainDA1_B = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA2_A) {
        this.errors.gainDA2_A = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.gainDA2_B) {
        this.errors.gainDA2_B = this.$t("NEW_DASHBOARD.GAIN_REQUIRED");
      }

      if (!this.sensorDto.porog_MCF) {
        this.errors.porog_MCF = this.$t("NEW_DASHBOARD.MCF_THRESHOLD_REQUIRED");
      }

      if (!this.sensorDto.porogArtInt) {
        this.errors.porogArtInt = this.$t(
          "NEW_DASHBOARD.ART_INT_THRESHOLD_REQUIRED"
        );
      }

      if (!this.sensorDto.zeroNumber) {
        this.errors.zeroNumber = this.$t("NEW_DASHBOARD.LOGICAL_ZEROS_REQUIRED");
      }

      if (!this.sensorDto.step) {
        this.errors.step = this.$t("NEW_DASHBOARD.TIME_STEP_REQUIRED");
      }

      if (this.sensorDto.isVoltageCircuit === null) {
        this.errors.isVoltageCircuit = this.$t(
          "NEW_DASHBOARD.INPUT_CIRCUIT_REQUIRED"
        );
      }

      return Object.keys(this.errors).length === 0;
    },

    submitForm() {
      console.log("Submit");
      if (this.validateForm()) {
        this.$emit("submit", this.sensorDto);
      } else {
        console.log(this.$t("NEW_DASHBOARD.FORM_INVALID"));
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 500;
  color: #464457;
  margin-bottom: 0.5rem;
}

.form-control,
.form-select {
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: none;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #f5f8fa;
  opacity: 1;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.925rem;
  color: #f64e60;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-control.is-invalid,
.form-select.is-invalid {
  border-color: #f64e60;
  padding-right: calc(1.5em + 1.3rem);
  background-position: right calc(0.375em + 0.325rem) center;
}

.row {
  margin-bottom: -1.5rem;
}

.col-md-6 {
  margin-bottom: 1.5rem;
}

.container {
  padding: 0;
}

/* Стили для select */
.form-select {
  padding-right: 2rem;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<template>
  <div class="d-flex flex-row w-100 my-4">
    <GraphTooltip
      :dynamicLabels="dynamicLabels"
      :highlightedPoints="highlightedPoints"
      :seriesColors="seriesColors"
      :tooltipVisible="tooltipVisible"
      :visibleSeries="visibleSeries"
      :scalingFactors="scalingFactors"
      target="mainGraph"
    >
      <template v-slot:title>
        <strong>Index: </strong>
        {{ highlightedIndex }}
      </template>
    </GraphTooltip>
    <div class="d-flex graph-container w-100 overlap-hidden">
      <div ref="mainGraph" class="w-100" :style="myStyles"></div>
    </div>
    <b-button 
        v-b-toggle="`sidebar-legend-${widget.i}-${isLowFreq?'lf':'hf'}-${chartId}`"
        class="legend-button position-absolute" 
        variant="link"
      >
        <b-icon icon="list" />
      </b-button>
    <b-collapse
      class="position-absolute legend pt-12 pr-8"
      v-if="dynamicLabels.length"
      :id="`sidebar-legend-${widget.i}-${isLowFreq?'lf':'hf'}-${chartId}`"
    >
      <label
        v-for="(label, index) in dynamicLabels"
        :key="index"
        class="custom-checkbox"
      >
        <input
          type="checkbox"
          :checked="visibleSeries[index]"
          @change="toggleSeriesVisibility(index, label)"
        />
        <span
          class="checkbox"
          :style="{ '--color': seriesColors[index] }"
        ></span>
        {{ label }}
        <b-button-group v-if="measureId" size="sm" class="download-buttons">
          <b-button
            title="Download Bin"
            @click="downloadBin(index)"
            :key="isBinDownloading[index]"
            variant="outline-primary"
            class="py-1"
          >
            <b-icon v-if="!isBinDownloading[index]" icon="hexagon"></b-icon>
            <b-icon v-else icon="circle-fill" animation="throb"></b-icon>
            Скачать .bin
          </b-button>
          <b-button
            title="Download Txt"
            v-if="!isLowFreq"
            @click="downloadTxt(index)"
            class="p-1"
            :key="isTxtDownloading[index]"
            variant="outline-primary"
          >
            <b-icon v-if="!isTxtDownloading[index]" icon="file-text"></b-icon>
            <b-icon v-else icon="circle-fill" animation="throb"></b-icon>
            Скачать .txt
          </b-button>
        </b-button-group>
      </label>
    </b-collapse>
  </div>
</template>

<script>
import Dygraph from "dygraphs";
import GraphTooltip from "./GraphTooltip.vue";
function getLang() {
  return localStorage.getItem("language") || "en";
}
export default {
  components: {
    GraphTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    measureId: {
      type: Number,
      required: false,
    },
    isLowFreq: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      chartId: Math.round(Math.random()*25565),
      visibleSeries: [],
      isBinDownloading: [],
      isTxtDownloading: [],
      seriesColors: [],
      dynamicLabels: [],
      scalingFactors: [],
      highlightedPoints: [],
      highlightedIndex: 0,
      tooltipVisible: true,
      initialRange: null,
      mainGraph: null,
      colorPalette: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
      ],
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.widget.h * 11}px`,
      };
    },
  },
  mounted() {
    if (
      this.data &&
      Object.keys(this.data).length > 0 &&
      this.$refs.mainGraph
    ) {
      this.initGraph();
    }
  },
  methods: {
    initGraph() {
      this.dynamicLabels = Object.keys(this.data);

      this.seriesColors = this.dynamicLabels.map(
        (label, index) => this.colorPalette[index % this.colorPalette.length]
      );

      this.setInitialSeriesVisibility();

      const seriesData = this.normalizeData();

      this.mainGraph = new Dygraph(this.$refs.mainGraph, seriesData, {
        labels: ["Index", ...this.dynamicLabels],
        strokeWidth: 1.5,
        showRangeSelector: true,
        visibility: this.visibleSeries,
        colors: this.seriesColors,
        legend: "never",
        highlightCallback: this.showCustomTooltip,
        unhighlightCallback: this.clearCustomTooltip,
        drawGrid: true,
        rightGap: 0,
        axes: {
          x: {
            pixelsPerLabel: 60,
            valueFormatter: function (ms) {
              const date = new Date(ms);
              return date.toLocaleDateString(getLang(), {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
          },
          y: {
            axisLabelWidth: 0,
            drawAxis: false,
            drawGrid: false,
          },
        },
      });

      this.initialRange = this.mainGraph.xAxisRange();

      this.$refs.mainGraph.addEventListener("wheel", this.handleWheelZoom);
    },
    setInitialSeriesVisibility() {
      this.visibleSeries = this.dynamicLabels.map((label) => {
        if (
          this.widget.disabledOptions &&
          this.widget.disabledOptions.includes(label)
        ) {
          return false;
        }
        return true;
      });
    },

    normalizeData() {
      const seriesData = [];
      const dataValues = Object.values(this.data);
      const dataLength = dataValues.reduce((max, current) => {
        return Math.max(max, current.length);
      }, 0);

      for (let i = 0; i < dataLength; i++) {
        const row = [i];
        this.dynamicLabels.forEach((key) => {
          row.push(this.data[key][i] !== undefined ? this.data[key][i] : null);
        });
        seriesData.push(row);
      }

      return seriesData;
    },

    showCustomTooltip(event, x, points) {
      this.highlightedPoints = points;
      this.highlightedIndex = x;
      this.tooltipVisible = true;
    },

    clearCustomTooltip() {
      this.tooltipVisible = false;
    },

    toggleSeriesVisibility(index, label) {
      this.$set(this.visibleSeries, index, !this.visibleSeries[index]);
      if (this.mainGraph) {
        this.mainGraph.updateOptions({
          visibility: this.visibleSeries,
        });
      }
      let disabledOptions = this.widget?.disabledOptions || [];

      if (disabledOptions.includes(label)) {
        disabledOptions = disabledOptions.filter((option) => option !== label);
      } else {
        disabledOptions.push(label);
      }

      this.$emit("update-widget", { ...this.widget, disabledOptions });
    },

    handleWheelZoom(event) {
      event.preventDefault();

      const normal = event.deltaY ? event.deltaY / Math.abs(event.deltaY) : 0;
      const percentage = normal * -0.1;
      const axis = this.mainGraph.xAxisRange();
      const delta = (axis[1] - axis[0]) * percentage;
      let newStart = axis[0] + delta;
      let newEnd = axis[1] - delta;

      if (newStart < this.initialRange[0]) newStart = this.initialRange[0];
      if (newEnd > this.initialRange[1]) newEnd = this.initialRange[1];

      if (newEnd - newStart > 10) {
        this.mainGraph.updateOptions({
          dateWindow: [newStart, newEnd],
        });
      }
    },

    generateRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    async downloadBin(chan) {
      const chanName = (this.isLowFreq ? "LF" : "HF") + chan + ".bin";

      this.$set(this.isBinDownloading, chan, true);

      const response = await this.axios.get(
        `api/oscillogram/measurements/${this.measureId}/${chanName}`,
        {
          responseType: "blob",
        }
      );

      this.$set(this.isBinDownloading, chan, false);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", chanName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async downloadTxt(chan) {
      const chanName = chan + ".txt";

      this.$set(this.isTxtDownloading, chan, true);

      const response = await this.axios.get(
        `api/oscillogram/measurements/${this.measureId}/${chanName}`,
        {
          responseType: "text/plain",
        }
      );

      this.$set(this.isTxtDownloading, chan, false);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", chanName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },

  beforeDestroy() {
    if (this.$refs.mainGraph) {
      this.$refs.mainGraph.removeEventListener("wheel", this.handleWheelZoom);
    }
    if (this.mainGraph) {
      this.mainGraph.destroy();
    }
  },
};
</script>

<style scoped>
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  /* Make them round */
  border: 2px solid var(--color);
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
  display: inline-block;
}

.custom-checkbox input:checked + .checkbox {
  background-color: var(--color);
}

.custom-checkbox input:not(:checked) + .checkbox {
  background-color: transparent;
}
.legend-button {
    z-index: 100;
}
.legend {
  width: 100%;
  background-color: rgba(255,255,255,0.8);
}

.download-buttons {
  margin-left: auto;
}
</style>

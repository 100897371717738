<template>
  <div class="position-relative">
    <LineChart
      class="position-relative"
      :data="highFreqData"
      :widget="widget"
      :measure-id="measureId"
      :is-low-freq="false"
    />
    <LineChart
      class="position-relative"
      :data="lowFreqDataLeft"
      :widget="widget"
      :measure-id="measureId"
      :is-low-freq="true"
    />
    <LineChart
      class="position-relative"
      :data="lowFreqDataRight"
      :widget="widget"
      :measure-id="measureId"
      :is-low-freq="true"
    />
  </div>
</template>
<script>
import LineChart from "./ScatIndicationsLineChart.vue";

export default {
  name: "OscillationChart",
  components: {
    LineChart,
  },
  props: {
    oscData: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    measureId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    lowFreqDataLeft() {
      return {
        "CH-0 LowFreq": this.oscData.osc_ch0_left_LowFreq
          ? this.oscData.osc_ch0_left_LowFreq
          : [],
        "CH-1 LowFreq": this.oscData.osc_ch1_left_LowFreq
          ? this.oscData.osc_ch1_left_LowFreq
          : [],
        "CH-2 LowFreq": this.oscData.osc_ch2_left_LowFreq
          ? this.oscData.osc_ch2_left_LowFreq
          : [],
      };
    },
    lowFreqDataRight() {
      return {
        "CH-0 LowFreq": this.oscData.osc_ch0_right_LowFreq
          ? this.oscData.osc_ch0_right_LowFreq
          : [],
        "CH-1 LowFreq": this.oscData.osc_ch1_right_LowFreq
          ? this.oscData.osc_ch1_right_LowFreq
          : [],
        "CH-2 LowFreq": this.oscData.osc_ch2_right_LowFreq
          ? this.oscData.osc_ch2_right_LowFreq
          : [],
      };
    },
    highFreqData() {
      return {
        "CH-0": this.oscData.osc_ch0AsIntegers
          ? this.oscData.osc_ch0AsIntegers
          : [],
        "CH-1": this.oscData.osc_ch1AsIntegers
          ? this.oscData.osc_ch1AsIntegers
          : [],
        "CH-2": this.oscData.osc_ch2AsIntegers
          ? this.oscData.osc_ch2AsIntegers
          : [],
      };
    },
  },
};
</script>

<template>
  <div class="d-flex flex-row w-100">
    <GraphTooltip
      :dynamicLabels="dynamicLabels"
      :highlightedPoints="highlightedPoints"
      :seriesColors="seriesColors"
      :tooltipVisible="tooltipVisible"
      :visibleSeries="visibleSeries"
      :scalingFactors="scalingFactors"
      target="mainGraph"
    >
      <template v-slot:title>
        <strong>Date: </strong>
        {{ highlightedDate }}
      </template>
    </GraphTooltip>
    <div class="d-flex graph-container w-100 overlap-hidden">
      <div
        id="graph"
        ref="mainGraph"
        class="w-100"
        :style="myStyles"
      ></div>
      <b-button
        v-b-toggle="`sidebar-legend-${widget.i}`"
        class="legend-button position-absolute"
        variant="link"
      >
        <b-icon icon="list" />
      </b-button>
      <b-collapse
        class="position-absolute legend pt-12 pr-8"
        v-if="dynamicLabels.length"
        :id="`sidebar-legend-${widget.i}`"
      >
        <label
          v-for="(label, index) in dynamicLabels"
          :key="index"
          class="custom-checkbox ml-4"
        >
          <input
            type="checkbox"
            :checked="visibleSeries[index]"
            @change="toggleSeriesVisibility(index, label)"
          />
          <span
            class="checkbox"
            :style="{ '--color': seriesColors[index] }"
          ></span>
          {{ labelNames[index] }}
        </label>
      </b-collapse>
    </div>
  </div>
</template>

<script>
function getLang() {
  return localStorage.getItem("language") || "en";
}
import Dygraph from "dygraphs";
import GraphTooltip from "./GraphTooltip.vue";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    GraphTooltip,
  },
  data() {
    return {
      visibleSeries: [],
      seriesColors: [],
      dynamicLabels: [],
      labelNames: [],
      scalingFactors: [],
      highlightedPoints: [],
      highlightedDate: null,
      tooltipVisible: false,
      initialRange: null,
      mainGraph: null,
      colorPalette: [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
        "#e6b8af",
        "#9fa8a2",
        "#8c96c6",
        "#a1d991",
        "#4fc3f7",
        "#f48fb1",
        "#673ab7",
        "#ffccbc",
        "#66bb6a",
        "#5c6578",
        "#009688",
        "#ff8a65",
        "#9575cd",
        "#26c6da",
        "#795548",
        "#ff9800",
        "#03a9f4",
        "#e040fb",
        "#c0ca33",
        "#4dd0e1",
        "#827717",
        "#f3e5f5",
        "#c8e6c9",
        "#dcedc8",
        "#b2dfdb",
        "#c0e3b5",
        "#90a4ae",
        "#eceff1",
        "#d4e157",
        "#00838f",
        "#ba68c8",
        "#00bcd4",
        "#009688",
        "#f44336",
        "#e91e63",
        "#2196f3",
        "#4caf50",
        "#ff5722",
        "#795548",
        "#ff9800",
        "#009688",
        "#e040fb",
        "#c0ca33",
        "#4dd0e1",
        "#827717",
        "#f3e5f5",
        "#c8e6c9",
        "#dcedc8",
        "#b2dfdb",
        "#c0e3b5",
        "#90a4ae",
        "#eceff1",
        "#d4e157",
        "#00838f",
        "#ba68c8",
        "#00bcd4",
        "#009688",
        "#f44336",
        "#e91e63",
        "#2196f3",
        "#4caf50",
        "#ff5722",
        "#795548",
        "#ff9800",
      ],
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.widget.h * 26}px`,
      };
    },
  },
  watch: {},
  mounted() {
    if (this.data && this.data.length > 0 && this.$refs.mainGraph) {
      this.initGraph();
    }
  },
  methods: {
    initGraph() {
      this.dynamicLabels = Object.keys(this.data[0].parameterValues);
      this.labelNames = this.dynamicLabels.map((dl) => {
        return this.widget.parameters[dl] != undefined
          ? this.widget.parameters[dl]
          : dl;
      });
      console.log(this.labelNames);

      // Фиксируем цвета для каждой метки на основе индекса
      this.seriesColors = this.dynamicLabels.map(
        (label, index) => this.colorPalette[index % this.colorPalette.length]
      );

      this.setInitialSeriesVisibility();

      const seriesData = this.normalizeData();

      this.mainGraph = new Dygraph(this.$refs.mainGraph, seriesData, {
        labels: ["Date", ...this.dynamicLabels],
        strokeWidth: 1.5,
        showRangeSelector: true,
        visibility: this.visibleSeries,
        colors: this.seriesColors,
        legend: "never",
        highlightCallback: this.showCustomTooltip,
        unhighlightCallback: this.clearCustomTooltip,
        drawGrid: true,
        rightGap: 0,
        axes: {
          x: {
            pixelsPerLabel: 60,
            valueFormatter: function (ms) {
              const date = new Date(ms);
              return date.toLocaleDateString(getLang(), {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
            axisLabelFormatter: function (ms) {
              const date = new Date(ms);
              return date.toLocaleDateString(getLang(), {
                month: "short",
                day: "numeric",
              });
            },
          },
          y: {
            axisLabelWidth: 0,
            drawAxis: false,
            drawGrid: false,
          },
        },
      });

      this.initialRange = this.mainGraph.xAxisRange();

      this.$refs.mainGraph.addEventListener("wheel", this.handleWheelZoom);
    },
    setInitialSeriesVisibility() {
      this.visibleSeries = this.dynamicLabels.map((label) => {
        if (
          this.widget.disabledOptions &&
          this.widget.disabledOptions.includes(label)
        ) {
          return false;
        }
        return true;
      });
    },

    normalizeData() {
      const seriesData = [];

      this.scalingFactors = this.dynamicLabels.map((key) => {
        const values = this.data.map((entry) => entry.parameterValues[key]);
        const minValue = Math.min(...values.filter((v) => v !== null));
        const maxValue = Math.max(...values.filter((v) => v !== null));
        return { minValue, maxValue, scale: maxValue - minValue || 1 };
      });

      this.data.forEach((entry) => {
        const date = new Date(entry.date);
        const scaledValues = this.dynamicLabels.map((key, index) => {
          const value = entry.parameterValues[key];
          const { minValue, scale } = this.scalingFactors[index];
          return value !== null ? (value - minValue) / scale : null;
        });
        seriesData.push([date, ...scaledValues]);
      });

      return seriesData;
    },

    formatDate(x) {
      return new Date(x).toLocaleString();
    },

    showCustomTooltip(event, x, points) {
      this.highlightedPoints = [...points];
      this.highlightedDate = this.formatDate(x);
      this.tooltipVisible = true;
    },

    clearCustomTooltip(event) {
      this.tooltipVisible = false;
    },

    toggleSeriesVisibility(index, label) {
      this.$set(this.visibleSeries, index, !this.visibleSeries[index]);
      if (this.mainGraph) {
        this.mainGraph.updateOptions({
          visibility: this.visibleSeries,
        });
      }
      let disabledOptions = this.widget?.disabledOptions || [];

      if (disabledOptions.includes(label)) {
        disabledOptions = disabledOptions.filter((option) => option !== label);
      } else {
        disabledOptions.push(label);
      }

      this.$emit("update-widget", { ...this.widget, disabledOptions });
    },

    handleWheelZoom(event) {
      event.preventDefault();

      const normal = event.deltaY ? event.deltaY / Math.abs(event.deltaY) : 0;
      console.log(normal);
      const percentage = normal * -0.1;
      const axis = this.mainGraph.xAxisRange();
      const delta = (axis[1] - axis[0]) * percentage;
      let newStart = axis[0] + delta;
      let newEnd = axis[1] - delta;

      if (newStart < this.initialRange[0]) newStart = this.initialRange[0];
      if (newEnd > this.initialRange[1]) newEnd = this.initialRange[1];

      if (newEnd - newStart > 10) {
        this.mainGraph.updateOptions({
          dateWindow: [newStart, newEnd],
        });
      }
    },

    generateRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  beforeDestroy() {
    if (this.$refs.mainGraph) {
      this.$refs.mainGraph.removeEventListener("wheel", this.handleWheelZoom);
    }
    if (this.mainGraph) {
      this.mainGraph.destroy();
    }
  },
};
</script>

<style scoped>
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  /* Make them round */
  border: 2px solid var(--color);
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
  display: inline-block;
}

.custom-checkbox input:checked + .checkbox {
  background-color: var(--color);
}

.custom-checkbox input:not(:checked) + .checkbox {
  background-color: transparent;
}
.legend-button {
  z-index: 100;
}
.legend {
  width: 100%;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>

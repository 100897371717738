<template>
  <b-container fluid class="px-2 my-2">
    <b-row>
      <b-col sm="12" md=12 lg="10" class="my-2 px-0">
        <div class="d-flex">
        <b-button
          size="sm"
          class="mx-1"
          :title="$t('COMMON.CALCULATE')"
          @click="handleCalculate"
        >
          <b-icon icon="arrow-repeat" />
        </b-button>
        <b-form-datepicker
          size="sm"
          class="mx-1"
          v-model="startDate"
          :locale="getLang()"
          :max="endDate"
          @input="onDateChange"
          :placeholder="$t('COMMON.START_DATE')"
          :date-format-options="dateFormat"
        />
        <b-form-datepicker
          size="sm"
          class="mx-1"
          v-model="endDate"
          :locale="getLang()"
          :min="startDate"
          @input="onDateChange"
          :placeholder="$t('COMMON.END_DATE')"
          :date-format-options="dateFormat"
        />
      </div>
      </b-col>
      <b-col :md="v.width" :key="k" v-for="(v, k) in filters" class="my-2 px-2">
        <b-form-input
          size="sm"
          type="text"
          v-if="v.type == 'text'"
          :placeholder="v.placeholder"
          v-model="v.value"
        />
      </b-col>
      <b-col>
        <b-form-checkbox
          v-if="showForecast"
          size="lg"
          class="align-middle my-3"
          v-model="enableForecast"
          switch
          @change="onToggleForecast"
        >
          {{ $t("DEVICE.FORECAST") }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BFormDatepicker, BButton, BRow, BCol } from "bootstrap-vue";

export default {
  name: "DatePicker",
  components: {
    BFormDatepicker,
    BButton,
  },
  props: {
    onCalculate: {
      type: Function,
      required: true,
    },
    initialStartDate: {
      type: Date,
      default: null,
    },
    initialEndDate: {
      type: Date,
      default: null,
    },
    showForecast: {
      type: Boolean,
      default: false,
    },
    enableForecast: {
      type: Boolean,
      default: false,
    },
    onToggleForecast: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      startDate: this.initialStartDate ? new Date(this.initialStartDate) : null,
      endDate: this.initialEndDate ? new Date(this.initialEndDate) : null,
    };
  },
  computed: {
    dateFormat() {
      return { year: "numeric", month: "numeric", day: "2-digit" };
    },
  },
  methods: {
    getLang() {
      return localStorage.getItem("language") || "en";
    },
    onDateChange() {},
    handleCalculate() {
      if (this.startDate && this.endDate) {
        const formattedStartDate = this.formatToISOString(this.startDate);
        const formattedEndDate = this.formatToISOString(this.endDate);
        this.onCalculate(formattedStartDate, formattedEndDate);
      } else {
        alert("Пожалуйста, выберите обе даты");
      }
    },
    formatToISOString(date) {
      const d = new Date(date);
      return d.toISOString();
    },
  },
};
</script>

<style scoped>
.date-picker-container {
  margin-bottom: 20px;
  flex: 1;
  max-width: 90%;
}

.forecast-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
    <grid-layout
      ref="gridLayout"
      :layout.sync="widgets"
      :key="widgets.length"
      :row-height="30"
      :auto-size="true"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="false"
      :use-css-transforms="true"
      :margin="[4,6]"
      :responsive="true"
      :prevent-collision="true"
      @layout-updated="onLayoutUpdated"
      class="mx-0 px-0"
    >
      <grid-item
        v-for="widget in widgets"
        :ref="'grid-item' + widget.i"
        :key="widget.i"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        :min-w="4"
        :max-w="12"
        :max-h="40"
        :min-h="20"
        @moved="$emit('layout-ready', $event)"
        @resized="$emit('layout-ready', $event)"
        class="mx-0 px-0 bg-white rounded overflow-none"
        dragAllowFrom=".drag-header"
      >
        <ChartWrapper
          @update-widget="updateWidget"
          @remove-widget="removeWidget"
          :widget="widget"
          :isForecastWidget="isForecastWidget(widget)"
        ></ChartWrapper>
      </grid-item>
    </grid-layout>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import ChartWrapper from "./charts/ChartWrapper";

const { GridLayout, GridItem } = VueGridLayout;

export default {
  components: {
    GridLayout,
    GridItem,
    ChartWrapper,
  },
  data() {
    return {
      pendingLayout: [],
      draggable: false,
      widgets: [],
    };
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
    propWidgets: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(widget => {
          return (
            Number.isInteger(widget.x) &&
            Number.isInteger(widget.y) &&
            Number.isInteger(widget.w) &&
            Number.isInteger(widget.h) &&
            widget.x >= 0 &&
            widget.y >= 0 &&
            widget.w > 0 &&
            widget.h > 0
          );
        });
      },
    },
  },
  computed: {
    validWidgets() {
      return this.validateWidgets(this.widgets);
    }
  },

  watch: {
    propWidgets(newValue, oldValue) {
      this.widgets = newValue;
    }
  },

  mounted() {
    this.widgets = [...this.propWidgets];
  },
  methods: {
    onLayoutUpdated(widgets) {
      this.$emit('layout-updated', widgets);
    },
    isForecastWidget(widget) {
      let device = this.devices.find((device) => device.id === widget.deviceId);
      if (device) {
        return device.forecast;
      }
      return false;
    },
    generateUniqueId() {
      return `widget-${Math.random().toString(36).substr(2, 9)}`;
    },
    updateWidget(widgetId) {
      this.$emit("update-widget", widgetId);
    },
    removeWidget(widgetId) {
      this.$emit("remove-widget", widgetId);
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <b-table-simple 
    small 
    hover
    responsive
    class="w-100"
    v-if="!selectedItem && !loading">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>№</b-th>
          <b-th>Дата</b-th>
          <b-th>ЛЭП</b-th>
          <b-th>Место</b-th>
          <b-th>1Отпр.</b-th>
          <b-th>2Отпр.</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item) in paginatedData"
          :key="item.id"
          @click="fetchDetail(item)"
        >
          <b-td>{{ item.id }}</b-td>
          <b-td>{{ formatDate(item.dateUTC) }}</b-td>
          <b-td>{{ item.powerlineName }}</b-td>
          <b-td>{{ formatDistanceString(item) || 'N/A' }}</b-td>
          <b-td>{{ item.sendEvent ? 'Да' : 'Нет' }}</b-td>
          <b-td>{{ item.send ? 'Да' : 'Нет' }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple >

    <div v-if="selectedItem && !loading">
      <b-button variant="link" @click="backToTable">
        <b-icon icon="arrow-left"/> Назад
      </b-button>
      <OscillogramsChart
      class="w-100"
       :widget="widget"
       :data="selectedItem"
       />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import OscillogramsChart from './OscillogramsChart';

export default {
  components: {
    OscillogramsChart,
  },
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      selectedItem: null,
      loading: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },
    paginatedData() {
      return this.data;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if(this.loading) return;
      this.loading = true;
      this.$emit("loading", this.loading);
      try {
        const start = (this.pagination.currentPage - 1);
        let parameters = {
            startDate: this.startDate,
            endDate: this.endDate,
            powerLineId: this.widget.deviceId,
            page: start,
            size: this.pagination.perPage,
            sort: "date",
            desc: true
        }
        if(this.filters.sensorId.value) {
          parameters['sensorIds[]'] = this.filters.sensorId.value;
        }
        const resp = await axios.get(`api/oscillogram/failures`, {
          params: parameters
        });
        this.data = resp.data.content;
        this.totalCount = resp.data.totalElements;
        console.log(resp.data);
        this.$emit("totalCountChanged", this.totalCount);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.$emit("loading", this.loading);
      }
    },
    formatDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchDetail(item) {
      this.loading = true;
      this.$emit("loading", this.loading);
      this.$emit('accidentSelected', item.id);
      try {
        const resp = await axios.get(`api/oscillogram/failures/${item.id}`);
        this.selectedItem = resp.data;
      } catch (error) {
        console.log(error);
        this.$emit('accidentSelected', null);
      } finally {
        this.loading = false;
        this.$emit("loading", this.loading);
      }
    },
    backToTable() {
      this.selectedItem = null;
      this.$emit('accidentSelected', null);
    },
    formatDistanceString(item) {
      const newItem = { ...item };
      const distance = newItem.sensorADistance;
      const sensorA = newItem.sensorAId;
      const sensorB = newItem.sensorBId || ' --';

      return `В ${distance} м от №${sensorA} к №${sensorB}`;
    },
    resetAndFetch() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
  watch: {
    startDate: {
      handler: 'resetAndFetch',
      immediate: true
    },
    endDate: {
      handler: 'resetAndFetch',
      immediate: true
    },
    pagination(newVal) {
      this.fetchData();
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div v-if="error && !loading" class="error-message">
      <p>Ошибка при загрузке данных. Пожалуйста, попробуйте позже.</p>
    </div>
    <div v-if="!loading" class="detail">
      <b-button variant="link" v-if="withBackBtn" @click="$emit('back')">
        <b-icon icon="arrow-left"/> Назад
      </b-button>
      <b-tabs
        justified
      >
        <b-tab title="Данные">
          <b-table-simple
            small
            responsive
            borderless
            stacked="sm"
            v-if="detail && !loading && !error"
          >
            <tbody>
              <tr>
                <td><strong>Дата:</strong> {{ formatDate(detail.dateUTC) }}</td>
                <td>
                  <strong>Дата сервера:</strong>
                  {{ formatDateUnix(detail.pc_DateTime, false) }}
                </td>
                <td>
                  <strong>Тип:</strong> {{ emergPoint(detail) }}
                </td>
              </tr>
              <tr>
                <td><strong>Датчик:</strong> {{ detail.sensorId }}</td>
                <td><strong>Версия датчика:</strong> {{ detail.sensorVersion }}</td>
                <td>
                  <strong>Авария по датчику, тчк:</strong>
                  {{ detail.sensorFlag || "N/A" }}
                </td>
              </tr>
              <tr>
                <td><strong>Филиал:</strong> {{ teamName }}</td>
                <td><strong>Аварийная dU/dt:</strong> {{ detail.rz }}</td>
              </tr>
              <tr>
                <td><strong>ЛЭП:</strong> {{ widget.deviceName }}</td>
                <td>
                  <strong>Напряжение аккум., В:</strong>
                  {{ detail.u_Battery }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Температура МК, C:</strong>
                  {{ ((detail.mc_Temperature / 10) - 273).toFixed(1) }}
                </td>
                <td><strong>Напряжение пит. МК, В:</strong> {{ detail.mc_VCC }}</td>
                <td>
                  <strong>Регистр статуса МК:</strong>
                  {{ detail.rst_Status }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Температура модема, C:</strong>
                  {{ detail.gsm_Temperature >> 0 || "N/A" }}
                </td>
                <td>
                  <strong>Напряж. пит. модема, В:</strong>
                  {{ detail.gsm_PwrVoltage / 1000 || "N/A" }}
                </td>
                <td>
                  <strong>Регистр control МК:</strong>
                  {{ detail.control_Reg }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Сигнал модема:</strong>
                  {{ detail.gsm_Signal_Level }}
                </td>
                <td>
                  <strong>Channel Bit Rate Error:</strong>
                  {{ detail.ch_bit_Rate_error }}
                </td>
                <td>
                  <strong>Опорное U МК, В:</strong>
                  {{ detail.mc_VREF / 1000 || "N/A" }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>GPS время события:</strong>
                  {{ detail.date }}
                </td>
                <td>
                  <strong>GPS точность, нс:</strong> {{ detail.gps_Accuracy }}
                </td>
              </tr>
              <tr>
                <td><strong>GPS широта:</strong> {{ detail.gps_latitude }}</td>
                <td><strong>GPS долгота:</strong> {{ detail.gps_longitude }}</td>
                <td>
                  <strong>GPS верное/неверное:</strong>
                  {{ detail.gps_Pvalid ? "верное" : "неверное" }}
                </td>
              </tr>
              <tr>
                <td><strong>GPS флаг:</strong> {{ detail.gps_Flag }}</td>
                <td>
                  <strong>GPS счётчик фронтов:</strong>
                  {{ detail.gps_FrontCounter }}
                </td>
                <td>
                  <strong>GPS координаты:</strong>
                  {{ detail.gps_Pvalid ? "верные" : "неверные" }}
                </td>
              </tr>
              <tr>
                <td><strong>Авария Ch0(1), тчк:</strong> {{ detail.flag }}</td>
                <td>
                  <strong>Авария Ch0(2), тчк:</strong>
                  {{ detail.mcf2StartPointA || "N/A" }}
                </td>
                <td>
                  <strong>Импульсов Ch0, шт.:</strong>
                  {{ detail.impulseCountCh0 }}
                </td>
              </tr>
              <tr>
                <td><strong>Авария Ch1(1), тчк:</strong> {{ detail.flagch1 }}</td>
                <td>
                  <strong>Авария Ch1(2), тчк:</strong>
                  {{ detail.mcf2StartPointB || "N/A" }}
                </td>
                <td>
                  <strong>Импульсов Ch1, шт.:</strong>
                  {{ detail.impulseCountCh1 }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Авария Ch2(1), тчк:</strong>
                  {{ detail.flagch2 || "N/A" }}
                </td>
                <td>
                  <strong>Авария Ch2(2), тчк:</strong>
                  {{ detail.mcf2StartPointC || "N/A" }}
                </td>
                <td>
                  <strong>Импульсов Ch2, шт.:</strong>
                  {{ detail.impulseCountCh2 || "N/A" }}
                </td>
              </tr>
            </tbody>
          </b-table-simple>
        </b-tab>
        <b-tab title="Уставки">
          <b-table-simple
            small
            responsive
            borderless
            stacked="sm"
          >
            <tbody>
              <tr>
                <td>
                  <strong>Уставка, dU/dt, о.е.:</strong>
                  {{ detail.ustavka_L || "N/A" }}
                </td>
                <td>
                  <strong>К усил chA DA1_Ch0:</strong>
                  {{ detail.gainDA1_A }}x
                </td>
                <td>
                  <strong>К усил chB DA1_Ch1:</strong>
                  {{ detail.gainDA1_B }}x
                </td>
              </tr>
              <tr>
                <td>
                  <strong>dt, точка:</strong>
                  {{ detail.step }}
                </td>
                <td>
                  <strong>К усил chA DA2_Ch2:</strong>
                  {{ detail.gainDA2_A }}x
                </td>
                <td>
                  <strong>К усил chB DA2:</strong>
                  {{ detail.gainDA2_B }}x
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Превышения:</strong>
                  {{ detail.countValue }}
                </td>
                <td>
                  <strong>Коэф. порога StdDev:</strong>
                  {{ detail.porog_MCF.toFixed(4) }}
                </td>
                <td>
                  <strong>Тип входных цепей:</strong>
                  {{ detail.isVoltageCircuit == 1 ? "Напряжения" : "Токовые" }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Коэф. порога лог. ед:</strong>
                  {{ detail.porogArtInt.toFixed(4) }}
                </td>
                <td>
                  <strong>Кол-во лог. нулей:</strong>
                  {{ detail.zeroNumber }}
                </td>
                <td>
                  <strong>Период связи, мин:</strong>
                  {{ detail.time_period }}
                </td>
              </tr>
            </tbody>
          </b-table-simple>
        </b-tab>
      </b-tabs>

      <div class="position-relative" v-if="detail && !loading">
        <ScatIndicationsChart :osc-data="detail" :widget="widget" :measure-id="detail.id" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ScatIndicationsChart from "./ScatIndicationsChart.vue";
import { mapState } from "vuex";

export default {
  components: {
    ScatIndicationsChart,
  },
  props: {
    withBackBtn: {
      type: Boolean,
      default: true,
    },
    sensorId: {
      type: Number,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      detail: null,
      loading: false,
      error: false,
    };
  },
  watch: {
    sensorId: {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchDetail(newId);
        }
      },
    },
  },
  computed: {
    ...mapState({
      defaultTeam: (state) => state.team.defaultTeam,
    }),

    teamName() {
      return this.defaultTeam ? this.defaultTeam.name : "Команда не выбрана";
    }
  },
  methods: {
    formatDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchDetail(id) {
      if(this.loading) return;
      this.loading = true;
      this.$emit("loading", this.loading);
      this.error = false;
      try {
        const response = await axios.get(`api/oscillogram/measurements/${id}`);
        this.detail = response.data;
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        this.error = true;
      } finally {
        this.loading = false;
        this.$emit("loading", this.loading);
      }
    },
    emergPoint(el) {
      if(el.emergChNumber == 0) return `Аварийная Ch0, ${el.flag}`
      if(el.emergChNumber == 1) return `Аварийная Ch1, ${el.flagch1}`
      if(el.emergChNumber == 2) return `Аварийная Ch2, ${el.flagch2}`
      else return "N/A";
    },
    formatServerDate(timestamp) {
      const date = new Date(timestamp);
      const formattedDate = date
        .toISOString()
        .slice(0, -1)
        .replace("Z", "")
        .slice(0, -3);
      return formattedDate;
    },
    formatDateUnix(time, isUnix = true) {
      const timestampInMilliseconds =
        time > 1e15 ? Math.floor(time / 1e6) : time;
      let timestampAdjustedFor1980Epoch = timestampInMilliseconds;

      if (isUnix) {
        timestampAdjustedFor1980Epoch += 315532800000;
      }
      const date = new Date(timestampAdjustedFor1980Epoch);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    },
  },
};
</script>
<style scoped>
.error-message {
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 20px;
}
</style>
